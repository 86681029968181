import React from 'react'
import './Home.css'
import Header from '../../Components/nav/headers'
import Content from '../../Components/Content/Content'


const Home = () => {
  return (
    <div className='container'>
      {/* <Header/> */}
      <Content/>
    </div>
  )
}

export default Home
